import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CountButton from './button';

const App = () => {
  const [users, setUsers] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    axios.get('https://www.nosekjan.cz/api.php')
      .then(response => setUsers(response.data))
      .catch(error => console.error(error));
  }, []);

  const addUser = () => {
    axios.post('https://www.nosekjan.cz/api.php', { name, email })
      .then(response => {
        setUsers([...users, { name, email }]);
        setName('');
        setEmail('');
      })
      .catch(error => console.error(error));
  };

  return (
    <div>
      <h1>Users</h1>
      <ul>
        {users.map((user, index) => (
          <li key={index}>{user.name} - {user.email}</li>
        ))}
      </ul>
      <h2>Add User</h2>
      <CountButton></CountButton>
      <input 
        type="text" 
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
      />
      <input 
        type="email" 
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <button onClick={addUser}>Add</button>
    </div>
  );
};

export default App;
