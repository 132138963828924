import {useState} from 'react';


function CountButton({arr, setSidebarOpen}){

    return(
        <>
            <button onClick={setSidebarOpen}>{arr}</button>
        </>
    )
}

export default CountButton;